.blockform {
  margin-bottom: 15px;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  flex-direction: column;
}
.labelfirstform {
  display: inline-block;
  width: 265px;
  font-size: 1.1rem;
}
.labelsecondform {
  display: inline-block;
}
.labelerror::after {
  content: " заполнять обязательно";
  color: orange;
  font-size: 0.8rem;
}
.labelerrorUA::after {
  content: " заповнювати обов'язково";
  color: rgb(243, 198, 18);
  font-size: 0.8rem;
}
.labelthirdform {
  display: inline-block;
  width: 130px;
}
.inpfirstform {
  width: 200px;
  border: 1px solid rgb(202, 202, 202);
  font-size: 1rem;
  border-radius: 5px;
  padding: 3px 10px;
  outline: none;
}
.inpfirstform:focus {
  outline: #7ef580 solid 1px;
  border: 1px solid transparent;
  outline-offset: -1px;
}
.inpfirstform_error {
  outline: 1px solid orange !important;
  outline-offset: -1px;
}
.inpsecondform {
  /* display: block !important; */
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid rgb(202, 202, 202);
  outline: none;
}
.inpsecondform::placeholder {
  color: rgb(172, 171, 171);
}
.inpsecondform:focus {
  outline: #7ef580 solid 1px;
  outline-offset: -1px;
  border: 1px solid transparent;
}
.inpsecondform_contacts {
  width: 225px;
  display: block;
}
.inpsecondform_error {
  outline: 1px solid orange !important;
  outline-offset: -1px;
}

.form {
  border-radius: 50px;
  padding: 30px 20px 2px 50px;
  background-color: #f4f4f4;
  border: 1px solid #e4e3e3;
}
.blockbut {
  margin-top: 35px;
  margin-bottom: 20px;
}
.but {
  width: calc(100% - 300px);
  height: 40px;
  border-radius: 18px;
  background: #8ff891;
  outline: none;
  border: #7dd97f;
  opacity: 1;
  /* color: #4e9d4d; */
  /* color: #3f7c3f; */
  color: #4e864e;
  font-weight: 500;
  font-size: 1.15rem;
  box-shadow: 0px 3px 2px rgba(195, 194, 194, 0.917);
  transition: 0.1s all linear;
  cursor: pointer;
}
/* .but:hover {
  background: #7ef580;  
  color: #3f7c3f;
} */
.but:active {
  box-shadow: 0px 1px 0px rgb(195, 194, 194);
  transform: translateY(-1px);
}
.rezult {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px 0 15px 0;
  color: grey;
}
.primechanie {
  margin-top: 20px;
  font-weight: 500;
}
.uploadfile {
  opacity: 0;
  visibility: hidden;
  position: fixed;
}
.getfiles {
  /* display: block; */
  /* width: 100%; */
}
.loadzone {
  line-height: 36px;
  padding-left: 15px;
  color: rgb(172, 171, 171);
  border-radius: 10px;
  border: 2px dashed grey;
  cursor: pointer;
}
.but_gray {
  margin-top: 5px;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 18px;
  background-color: rgb(236, 235, 235);
  outline: none;
  border: 1px solid rgb(202, 202, 202);
  opacity: 0.8;
  color: gray;
  text-decoration: none;
  font-size: 0.95rem;
  box-shadow: 0px 3px 2px rgb(195, 194, 194);
  transition: 0.1s all linear;
  cursor: pointer;
}
.but_gray:hover {
  border: 1px solid rgb(182, 182, 182);
  color: rgb(77, 77, 77);
}
.but_gray:active {
  box-shadow: 0px 0px 0px rgb(195, 194, 194);
}

.messok {
  border-radius: 25px;
  padding: 15px 17px 15px 17px;
  top: 340px;
  left: 50%;
  right: auto;
  max-width: 305px;
  transform: translateX(-50%);
  background-color: white;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out;
  z-index: 2;
  font-size: 1.2rem;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); */
}
.messok_active {
  visibility: visible;
  opacity: 1;
}
.messok__backfon {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: -50%;
  bottom: 0;
  background: darkslategrey;
  opacity: 0.5;
  z-index: 1;
}
.messok__backfon_active {
  display: block;
}
.messok__color_ok {
  border: 2px solid #7ef580;
}
.messok__color_err {
  border: 2px solid orange;
}
.messok__but {
  display: block;
  margin: 30px auto 0 auto;
  padding: 2px 22px 3px 22px;
  border-radius: 12px;
  border: none;
  /* border: 2px solid rgb(178, 177, 177); */
  /* box-shadow: 0px 2px 3px rgb(195, 194, 194); */
}
.messok__but:hover {
  /* border: 2px solid rgb(205, 203, 203); */
  box-shadow: 0px 0px 0px rgb(195, 194, 194);
}
.messok__but_ok {
  background-color: #7ef580;
  border: 1px solid #53f956;
  color: #216523;
  outline: 1px solid transparent;
  transition: outline 0.1s linear;
}
.messok__but_ok:hover {
  outline: 1px solid #4de54f;
}
.messok__but_err {
  background-color: orange;
  border: 1px solid rgb(220, 144, 2);
  color: rgb(68, 44, 1);
  outline: 1px solid transparent;
  transition: outline 0.1s linear;
}
.messok__but_err:hover {
  outline: 1px solid rgb(214, 140, 2);
}
.metka {
  position: absolute;
  top: -80px;
}
.about {
  position: relative;
}
:root {
  --basewidth1: 350px;
  --basewidth2: 170px;
  --basewidth3: 140px;
  --basewidth4: 120px;
  --basewidth5: 35px;
  --basewidth6: 20px;
  --basewidth7: 50px;
  --basewidth8: 60px;
}

.round1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 120px;
  right: 0;
  background-color: #95f397;
  width: var(--basewidth1);
  height: var(--basewidth1);
  border-radius: 50%;
  z-index: 10;
}
.round2 {
  position: absolute;
  top: 430px;
  right: 20px;
  background-color: #70c271;
  width: var(--basewidth2);
  height: var(--basewidth2);
  border-radius: 50%;
  z-index: 11;
}
.round3 {
  position: absolute;
  top: 40px;
  right: 190px;
  background-color: #70c271;
  width: var(--basewidth2);
  height: var(--basewidth2);
  border-radius: 50%;
  z-index: 9;
}
.round4 {
  position: absolute;
  top: 420px;
  right: 220px;
  background-color: #c1ffc2;
  width: var(--basewidth3);
  height: var(--basewidth3);
  border-radius: 50%;
  z-index: 12;
}
.round5 {
  position: absolute;
  top: 5px;
  right: 25px;
  background-color: #c1ffc2;
  width: var(--basewidth4);
  height: var(--basewidth4);
  border-radius: 50%;
  z-index: 8;
}
.round6 {
  position: absolute;
  top: 80px;
  right: 10px;
  background-color: #70c271;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 13;
}
.round7 {
  position: absolute;
  top: 380px;
  right: 350px;
  background-color: #70c271;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 7;
}
.round8 {
  position: absolute;
  top: 30px;
  right: 340px;
  background-color: #95f397;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 7;
}
.round9 {
  position: absolute;
  top: 600px;
  right: 335px;
  background-color: #95f397;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 7;
}
.round10 {
  position: absolute;
  top: 590px;
  right: 300px;
  background-color: #70c271;
  width: var(--basewidth6);
  height: var(--basewidth6);
  border-radius: 50%;
  z-index: 7;
}
.roundleft1 {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #95f397;
  width: var(--basewidth7);
  height: var(--basewidth7);
  border-radius: 50%;
  z-index: 7;
}
.roundleft2 {
  position: absolute;
  top: 72px;
  left: 0;
  background-color: #70c271;
  width: var(--basewidth6);
  height: var(--basewidth6);
  border-radius: 50%;
  z-index: 7;
}
.roundleft3 {
  position: absolute;
  top: 400px;
  left: 20px;
  background-color: #95f397;
  width: var(--basewidth8);
  height: var(--basewidth8);
  border-radius: 50%;
  z-index: 7;
}
.roundleft4 {
  position: absolute;
  top: 380px;
  left: 20px;
  background-color: #c1ffc2;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 6;
}
.roundleft5 {
  position: absolute;
  top: 580px;
  left: 55px;
  background-color: #70c271;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  z-index: 6;
}
.roundleft6 {
  position: absolute;
  top: 560px;
  left: 70px;
  background-color: #c1ffc2;
  width: var(--basewidth5);
  height: var(--basewidth5);
  border-radius: 50%;
  transform: scale(0.85);
  z-index: 5;
}
.about {
  margin: 30px 0 0 0;
  height: 780px;
}
.about__title {
  margin: 0 0 0 100px;
  font-weight: 500;
  font-size: 3.1rem;
}
.about__list {
  margin: 10px 0 0 90px;
  font-size: 1.4rem;
}
.about__list-item {
  padding-bottom: 5px;
}
.about__paragraf {
  margin: 0 0 0 100px;
  padding: 0;
  font-size: 2.4rem;
  font-weight: 500;
}
.about__message {
  display: inline-block;
  margin: 80px 0 0 350px;
  text-align: center;
  background: url(./img/arrow_main.gif) no-repeat center bottom;
  background-size: 200px 100px;
  padding-bottom: 130px;
  font-size: 2.3rem;
  transform: translateX(-50%);
}
.about__message-highlight {
  font-weight: 700;
}
.about__add {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  padding: 25px;
  border-radius: 50%;
  color: rgb(72, 72, 72);
}

.react-datepicker-popper {
  border-radius: 20px; /* Задайте радиус скругления границ */
  border: none;
}
.react-datepicker__day.react-datepicker__day--selected {
  background: #7ef580;
  color: black;
  border: 1px solid transparent;
}
.react-datepicker__navigation.react-datepicker__navigation--next,
.react-datepicker__navigation.react-datepicker__navigation--previous {
  margin-top: 10px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: rgb(209, 208, 208) !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: white !important;
}
.react-datepicker__month-container {
  padding: 3px;
}
.react-datepicker {
  border-radius: 15px !important;
}
.react-datepicker__header {
  background-color: #fff !important;
}
.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 5px !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 12px !important;
}
.react-datepicker__input-time-container {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  margin: 5px 15px 10px 15px !important;
  float: none !important;
  width: auto !important;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block !important;
  width: auto !important;
}
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input {
  margin-left: 0 !important;
}

@keyframes opacityTitle {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 995px) {
  .round9 {
    right: 270px;
  }
  .round10 {
    right: 240px;
  }
}
@media screen and (max-width: 960px) {
  :root {
    --basewidth1: 300px;
    --basewidth2: 150px;
    --basewidth3: 130px;
    --basewidth4: 110px;
    --basewidth5: 35px;
    --basewidth6: 20px;
    --basewidth7: 50px;
    --basewidth8: 60px;
  }
  .round2 {
    top: 390px;
    right: 20px;
  }
  .round4 {
    top: 380px;
    right: 200px;
  }
  .about__add {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 920px) {
  .about__list {
    margin: 10px 0 0 60px;
    font-size: 1.3rem;
  }
  .about__list-item {
    padding-bottom: 7px;
  }
  .about__paragraf {
    margin: 0 0 0 70px;
    padding: 0;
    font-size: 2.4rem;
    font-weight: 500;
  }
  .about__title {
    margin: 0 0 0 70px;
    font-weight: 500;
    font-size: 3rem;
  }
  .about__message {
    font-size: 2.1rem;
    margin: 110px 0 0 330px;
  }
  .round9 {
    right: 230px;
  }
  .round10 {
    right: 200px;
  }
}
@media screen and (max-width: 855px) {
  .roundleft1 {
    display: none;
  }
  .roundleft2 {
    display: none;
  }
  .roundleft3 {
    display: none;
  }
  .roundleft4 {
    display: none;
  }
  .roundleft5 {
    display: none;
  }
  .roundleft6 {
    display: none;
  }
  .about__list {
    margin: 10px 0 0 0px;
    font-size: 1.3rem;
  }
  .about__list-item {
    padding-bottom: 7px;
  }
  .about__paragraf {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 2.4rem;
    font-weight: 500;
  }
  .about__title {
    margin: 0 0 0 10px;
    font-weight: 500;
    font-size: 3rem;
  }
  .about__message {
    font-size: 2.1rem;
    margin: 110px 0 0 240px;
  }
}
@media screen and (max-width: 790px) {
  .about {
    margin: 30px 0 0 0;
    height: 820px;
  }
  .about__list {
    margin: 10px 0 0 0px;
    font-size: 1.2rem;
    width: 300px;
  }
  .about__list-item {
    padding-bottom: 7px;
  }
  .about__paragraf {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 2.1rem;
    font-weight: 500;
  }
  .about__title {
    margin: 0 0 0 10px;
    font-weight: 500;
    font-size: 2.8rem;
  }
  .about__message {
    display: block;
    font-size: 1.8rem;
    margin: 50px auto;
    padding-bottom: 100px;
    transform: translateX(0%);
  }
  .round10,
  .round9 {
    display: none;
  }
  .round7 {
    top: 340px;
    right: 310px;
  }
  .round8 {
    top: 20px;
    right: 310px;
  }
}

@media screen and (max-width: 800px) {
  .but {
    width: calc(100% - 200px);
  }
}
@media screen and (max-width: 680px) {
  :root {
    --basewidth1: 270px;
    --basewidth2: 120px;
    --basewidth3: 100px;
    --basewidth4: 80px;
    --basewidth5: 25px;
    --basewidth6: 15px;
    --basewidth7: 40px;
    --basewidth8: 50px;
  }
  .round2 {
    top: 370px;
    right: 20px;
  }
  .round3 {
    top: 40px;
    right: 150px;
  }
  .round4 {
    top: 360px;
    right: 180px;
  }
  .about__add {
    font-size: 1.5rem;
  }
  .round7 {
    top: 330px;
    right: 280px;
  }
  .round8 {
    top: 20px;
    right: 270px;
  }
}

@media screen and (max-width: 650px) {
  .but {
    width: calc(100% - 100px);
  }
}
@media screen and (max-width: 630px) {
  .round1,
  .round2,
  .round3,
  .round4,
  .round8,
  .round7 {
    display: none;
  }
  .round5 {
    top: 0px;
    right: 0px;
    transform: scale(60%);
  }
  .round6 {
    top: 75px;
    right: 10px;
  }
  .about__list {
    margin: 10px 0 0 0px;
    font-size: 1.2rem;
    width: 100%;
  }
  .about {
    margin: 30px 0 0 0;
    height: 650px;
  }
}

@media screen and (max-width: 440px) {
  .metka {
    position: absolute;
    top: -70px;
  }
  .but {
    width: calc(100% - 50px);
  }
  .about__list {
    font-size: 1.1rem;
  }
  .about__list-item {
    padding-bottom: 5px;
  }
  .about__message {
    margin: 40px auto;
    font-size: 1.6rem;
    padding-bottom: 100px;
  }
  .about {
    margin: 30px 0 0 0;
    height: 600px;
  }
}
@media screen and (max-width: 400px) {
  .metka {
    position: absolute;
    top: -70px;
  }
  .but {
    width: calc(100% - 50px);
  }
  .about__list {
    font-size: 1.1rem;
  }
  .about__list-item {
    padding-bottom: 5px;
  }
  .about__message {
    margin: 40px auto;
    font-size: 1.6rem;
    padding-bottom: 100px;
  }
  .about {
    margin: 25px 0 0 0;
    height: 630px;
  }
  .about__paragraf {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 1.9rem;
    font-weight: 500;
  }
  .about__title {
    margin: 0 0 0 10px;
    font-weight: 500;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 370px) {
  .about {
    margin: 20px 0 0 0;
    height: 670px;
  }
  .about__paragraf {
    margin: 0 0 0 10px;
    padding: 0;
    font-size: 1.7rem;
    font-weight: 500;
  }
  .about__title {
    margin: 0 0 0 10px;
    font-weight: 500;
    font-size: 2.3rem;
  }
  .about__message {
    font-size: 1.4rem;
  }
  .round5,
  .round6 {
    display: none;
  }
}
@media screen and (max-width: 345px) {
  .about {
    margin: 20px 0 0 0;
    height: 720px;
  }
}
@media screen and (max-width: 327px) {
  .about {
    margin: 20px 0 0 0;
    height: 740px;
  }
}

.footer {
  margin-top: 30px;
  background: #d9d8d8;

  /* background: linear-gradient(#d9d9d9, #f9f9f9, #e4e3e3); */
  padding: 25px 35px 16px 35px;
  border-top: 2px solid rgb(188, 187, 187);
  z-index: 10;
}
.footerDispetcher {
  margin-top: 30px;
  /* margin-top: auto; */
  background: #d9d8d8;

  /* background: linear-gradient(#d9d9d9, #f9f9f9, #e4e3e3); */
  padding: 15px 35px 7px 35px;
  border-top: 2px solid rgb(188, 187, 187);
  z-index: 10;
}
.footer-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 980px;
  margin: 0 auto;
}
.footer__logo {
  color: rgb(108, 108, 108);
  background: url(../header/img/logo_small.png) no-repeat left center;
  background-size: 40px 40px;
  padding-left: 50px;
  height: 40px;
  line-height: 50px;
  font-weight: 700;
  font-style: italic;
}
.footer__add {
  color: grey;
  width: 280px;
  margin-bottom: 0.7rem;
}
.telbox {
  height: 20px;
  padding-left: 35px;
  line-height: 20px;
}
.telbox:first-child {
  background: url(./img/tel_black_icon.png) no-repeat left center;
  background-size: 21px 20px;
}
.mailbox {
  background: url(./img/mail_black_icon.png) no-repeat left center;
  background-size: 21px 18px;
  height: 20px;
  padding-left: 35px;
  line-height: 20px;
}
.counter {
  border: 1px solid red;
  width: 88px;
  height: 15px;
  color: black;
}

@media screen and (max-width: 440px) {
  .footer {
    padding: 15px 25px;
  }
  .footer__add {
    color: grey;
    width: 260px;
    font-size: 0.85rem;
  }
  .footer__logo {
    color: rgb(108, 108, 108);
    background: url(../header/img/logonew.gif) no-repeat left center;
    background-size: 30px 30px;
    padding-left: 40px;
    height: 40px;
    line-height: 50px;
    font-weight: 500;
    font-style: italic;
  }
}

@media screen and (max-width: 768px) {
  .staticinfooter {
    margin-bottom: 25px;
  }
}

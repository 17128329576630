.search__block {
  position: relative;
  margin: 30px 0 25px 0;
  border-radius: 3px;
  padding: 0 0 6px 0;
  /* background-color: #f8f8f8; */
  box-sizing: content-box;
}
.search__block_active {
  box-shadow: 0px 1px 2px 1px rgba(161, 161, 161, 0.5);
  /* transform: translateY(-1px); */
  border: none;
}
.search__input {
  width: 100%;
  outline: none;
  padding: 7px 20px 7px 60px;
  border-radius: 3px;
  /* border: 1px solid rgb(227, 226, 226); */
  border: 2px solid rgba(193, 193, 193, 0.54);
  font-size: 1rem;
  color: rgb(187, 186, 186);
  background: url(img/lupa.gif) no-repeat left 12px center;
  background-size: 22px 22px;
}
.search__input_hide {
  border: 2px solid transparent;
}
.search__input:focus {
  /* outline: #8ff891 solid 1px; */
  /* border: 2px solid #8ff891; */
}
.search__input::placeholder {
  color: rgb(187, 186, 186);
}
.outserchnames {
  width: 100%;
  padding: 0 10px;
  margin: 0;
}
.outserchnames_active {
  margin-top: 20px;
}
.outserchnames__item {
  display: block;
  margin-bottom: 7px;
  padding: 5px 15px;
  /* border: 2px solid transparent; */
  transition: all 0.1s ease-out;
}
.outserchnames__item:hover {
  border-radius: 5px;
  /* border-color: rgba(194, 192, 192, 0.337); */
  /* background-color: #8ff891d8; */
  background-color: rgba(187, 186, 186, 0.193);
}

.workinfo {
  margin: 0;
  padding: 0;
  color: gray;
  line-height: 0.8rem;
  font-size: 0.8rem;
}
.listworks__item {
  margin: 0 0 5px 0;
  color: rgb(173, 172, 172);
}
.namefinwork {
  padding: 0 30px;
}
.soderg {
  text-indent: 2rem;
}
.soderg p {
  margin-bottom: 7px !important;
  text-align: justify;
}

.searchlink {
  display: block;
  text-decoration: none;
  color: rgb(139, 138, 138);
}
.searchlink:hover {
  color: rgb(97, 96, 96);
}
.downloadbut {
  margin: 40px auto 50px auto;
  display: block;
  width: 260px;
  height: 34px;
  line-height: 34px;
  border-radius: 18px;
  background: #8ff891;
  outline: none;
  border: none;
  color: #3f7c3f;
  font-weight: 500;
  font-size: 1rem;
  box-shadow: 0px 5px 3px rgb(195, 194, 194);
  transition: 0.1s background-color linear;
  text-decoration: none;
  text-align: center;
  text-indent: 0;
}
.downloadbut:hover {
  background: #7ef580;
  color: #418840;
}
.downloadbut:active {
  box-shadow: 0px 1px 0px rgb(195, 194, 194);
  transform: translateY(-1px);
}
.listpages {
  margin: 30px 0 25px 0;
}
.listpages__item {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.listpages__link {
  color: gray;
  text-decoration: none;
}
.listpages__link:hover {
  color: rgb(96, 96, 96);
  text-decoration: underline;
}
.listpages__link_active {
  font-weight: 500;
  color: rgb(81, 80, 80);
  text-decoration: none;
}
.listpages__item_first {
  margin-right: 20px;
}
.listpages__item_last {
  margin-left: 10px;
}
.listpages__item_disabled {
  visibility: hidden;
}
.paginat {
  text-align: center;
  margin-top: 50px;
}
.paginat li {
  list-style: none;
  display: inline-block;
  padding: 0 8px;
  font-weight: 500;
  color: #acabab;
}
.paginat li:hover {
  cursor: pointer;
  text-decoration: underline;
  color: grey;
}
li.paginat_liactive {
  text-decoration: underline;
  font-weight: 500;
  color: grey;
}
.buttonsblock {
  display: flex;
  margin-top: 30px;
  column-gap: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .search__input {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}

.wrap {
  max-width: 980px;
  margin: 0 auto;
}
.wrap_admin {
  max-width: 100%;
  margin: 0 auto;
  background-color: rgb(240, 238, 238);
  margin: 0 20px 0 20px;
  padding-bottom: 5px;
}
.mainconteiner {
  position: relative;
  min-height: 100vh;
  /* display: flex;
  flex-direction: column; */
  /* background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)),
    url(./header/img/logofon.jpg) repeat-y top center;

  background-size: 150%; */
}
.mainfon {
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
    url(./header/img/logofon.jpg) repeat-y top center;

  background-size: 90%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: translateZ(0); */
  z-index: 0;
}
.darcking::before {
  position: fixed;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: gray;
  z-index: 2;
  opacity: 0.5;
}
@media screen and (max-width: 1000px) {
  .wrap {
    padding: 0 15px;
  }
}

@media screen and (max-width: 560px) {
  .wrap {
    padding: 0 7px;
  }
}
